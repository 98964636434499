import React from "react";
import styled from 'styled-components';
import { renderToStaticMarkup } from 'react-dom/server';

export default class Form extends React.Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.state = {
      status: ""
    }; 
  }

  render(props) {
    const { status } = this.state;
    const markup = renderToStaticMarkup(this.props.children);

    return (

      <HtmlForm onSubmit={this.submitForm} action={"https://formspree.io/" + this.props.formid} method="POST">
        
        <div dangerouslySetInnerHTML={{__html: markup}} />
        
        {status === "SUCCESS" ? <p><br/><strong>Thanks for your message, we will get back to you soon!</strong></p> : <button>Submit</button>}
        {status === "ERROR" && <p>Ooops! There was an error.</p>}
      </HtmlForm>
 
    );
  }

  submitForm(ev) {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        this.setState({ status: "SUCCESS" });
      } else {
        this.setState({ status: "ERROR" });
      }
    };
    xhr.send(data);
  }
}

const HtmlForm = styled.form`
  div {
    margin: 40px auto;
    display: flex;
    flex-direction: column;
  }

  button {
    margin-top: 20px;
    display: flex;
    align-self: flex-end;
    background: #272f32;
    border: 1px solid #fff;
    padding: 10px 15px;
    margin-left: 20px;
    font-size: 16px;
    color: #fff;

    &:hover {
      background: #fff;
      color: #272f32;
      cursor: pointer;
    }

    @media screen and (max-width: 479px) {
      width: 100%;
      text-align: center;
      display: block;
    }
  }

  input[type="text"].gotcha {
    margin: 0;
    border: 0;
    height: 0;
    padding: 0;
  }
  
  input[type="text"],
  input[type="email"] {
    background: none;
    border-width: 0 0 2px 0;
    border-color: red;
    color: #fff;
    outline: none;
    margin-bottom: 20px;
    font-size: 16px;
    padding: 5px;

     &:focus {
      outline-color: red;
    }
  }

  textarea {
    background: none;
    border-color: red;
    color: #fff;
    outline: none;
    border-width: 0 0 2px 0;
    margin-bottom: 20px;
    font-size: 16px;
    padding: 5px;
    min-height: 100px;
  }

  input[type=checkbox] {
    margin-right: 10px;
    padding: 5px;
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    background: white;
    vertical-align: middle;
    border: 2px solid red;

    &:checked {
      background: red;
      border: 2px solid white;
    }
  }
  
`
    