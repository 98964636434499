import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import styled from 'styled-components';
import BgImage from '../images/banner-images/contact-banner.jpg';
import Banner from "../components/banner";
import ContactForm from "../components/form";


const ContactPage = () => (
  <Layout>
    <Seo title="Contact" description="Take the next step and get in contact today with ThowardMedia." />

    <Banner title="Contact" image={BgImage} />

    <Wrapper>
      <p>Have a project in mind or need some help? Get in contact with ThowardMedia by filling out the form below and we'll get back to you.</p>
 
      <ContactForm formid="mjvarveo" messagelabel="Message">
        <input type="text" name="name" placeholder="Your name" required/>
        <input type="email" name="email" placeholder="Your email address" required />
        <textarea name="message" placeholder="Take the next step and get in contact today with ThowardMedia" required />
        <input type="text" name="_gotcha" style={{display: `none`}} />
      </ContactForm>
    </Wrapper>

  </Layout>
)

export default ContactPage

const Wrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
  color: #fff;
  
  p {
    text-align: center;
  }


  @media(max-width: 980px) {
    padding: 0 20px;
  }
`